import React, { createRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableCell, TableBody, TableHead, TableRow } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Loader from '../../loader';
import { Modal } from 'react-bootstrap';
import configuration from '../../../configurations';
import { connect } from 'react-redux';
import customStyle from '../../../Assets/CSS/stylesheet_UHS';
import CommonTable from '../../CommonScreens/commonTable';
import i18n from '../../../i18next';
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import planConfig from "../../../planConfig";
import Sample from '../../CommonScreens/sampleTextField';
import axios from 'axios';
import { isnoSmartShare } from '../../../clientIDConfig';
const CustomButton = withStyles(
    customStyle.viewBtn
)(Button);

const ProceedButton = withStyles(
    customStyle.proceedBtn
)(Button);

const ViewButton = withStyles(
    customStyle.viewBtn
)(Button);
const ViewButton2 = withStyles(
    customStyle.viewBtn2
)(Button);

const styles = theme => ({
    table: {
        minWidth: 700,
    },
});


const StyledTableCell = withStyles(theme => (customStyle.tableCell))(TableCell);
const StyledTableCell1 = withStyles(theme => (customStyle.tableCell1))(TableCell);

const StyledTableRow = withStyles(theme => (customStyle.tableRow))(TableRow);

function createData1(NAME, UHS1, UHS2, UHS3, UHS4,UHS5,UHS6) {
    return { NAME, UHS1, UHS2, UHS3, UHS4,UHS5,UHS6 };
}

const ACSMrowUhsAll=[
    createData1('One Member per Household	', '$3,000', '$4,500', '$7,500', '$10,500','$15,000','$18,000'),
    createData1('Two Members per Household	', '$6,000', '$9,000','$15,000','$21,000','$30,000','$36,000'),
    createData1('Three or more Members per Household	', '$9,000','$13,500	','$22,500','$31,500','$45,000','$54,000'),
];

const ACSMrowUhs=[
    createData1('One Member per Household	',  '$4,500',  '$10,500','$18,000'),
    createData1('Two Members per Household	',  '$9,000','$21,000','$36,000'),
    createData1('Three or more Members per Household	', '$13,500	','$31,500','$54,000')
];

const CrudButton = withStyles(
    customStyle.crudBtn,
)(Fab);

const IFrameStyle = {
  height: "430px",
  width: "100%",
  scrollbarWidth: "auto",
  scrollbarColor: "#cccccc #ffffff",
  webkit_scrollbar: { width: 8 },
  webkit_scrollbar_track: { background: "#ffffff" },
  webkit_scrollbar_thumb: {
    backgroundColor: "#cccccc",
    borderRadius: 10,
    border: "3px solid #ffffff",
  },
};

class ViewQuote extends React.Component {
    constructor(props) {
        super(props);
        this.popupRef = createRef();
        this.state = {
            quoteData: [],
            loaderShow: false,
            modalShow: false,
            headerData:[],
            familyDetails_ModalShow : false,
            otherQuote : [],
            generalQuote : [],
            generalQuoteHeader : [],
            tooltip:[],
            otherQuoteData : [],
            clientId:sessionStorage.getItem('CLIENT_ID'),
            ACSMPlanAll:['UHS1','UHS2','UHS3','UHS4','UHS5','UHS6'],
            ACSMPlan:['UHS2','UHS4','UHS6'],
            smartShareModel : false,
            ACSMModal : false,
            msgModal : false,
            errMsg : '',
            confirmationModal : false,
            easyShareModal : false,
            acsmFlag : false,
            iframeURL : '',
            email:null,
            isvalidEmail:true,
            validationError:false,
            isHovered: false,
            isPopupVisible: false,
        }
    }

    getQuoteData = (val) => {
        console.log('val 0 0 - - ', val);
        
            fetch(configuration.baseUrl + '/plan/viewQuote/'+ this.props.subId + '/' + sessionStorage.getItem('CLIENT_ID') + '/' + val)
            .then((response) => response.json())
            .then(response => {
                let acsmFlag = false;
                if(sessionStorage.getItem('CLIENT_ID').toString() === '4350' 
                || sessionStorage.getItem('CLIENT_ID').toString() === '6548' 
                || sessionStorage.getItem('CLIENT_ID') === '4367'
                || sessionStorage.getItem('CLIENT_ID') === '5647'
                || sessionStorage.getItem('CLIENT_ID') === '5540' 
                || sessionStorage.getItem('CLIENT_ID') === '4376' 
                || sessionStorage.getItem('CLIENT_ID') === '4391' 
                || sessionStorage.getItem('CLIENT_ID') === '4377'
                || sessionStorage.getItem('CLIENT_ID').toString() === '5558'
                || sessionStorage.getItem('CLIENT_ID').toString() === '4386'
                || sessionStorage.getItem('CLIENT_ID').toString() === '5696'
                || sessionStorage.getItem('CLIENT_ID').toString() === '4397'
                || sessionStorage.getItem('CLIENT_ID').toString() === '4390'
                || sessionStorage.getItem('CLIENT_ID').toString() === '5596'
                ){
                    acsmFlag = false
                } else {
                    acsmFlag = true
                }
                this.setState({
                    loaderShow: false,
                    generalQuote : response.quote,
                    generalQuoteHeader : response.header || response.heder,
                    otherQuoteData : response.otherFees,
                    quoteData: response.data,
                    headerData:response.header,
                    tooltip:response.shareableTooltip,
                    acsmFlag : acsmFlag,
                    program: val
                });
            })
            .catch(error => {
              console.log(error);
            })
    }
    handleMouseEnter = () => {
        this.setState({ isHovered: true, isPopupVisible: true });
      };
    
      handleMouseLeave = () => {
        this.setState({ isHovered: false });
        this.hoverTimeout = setTimeout(() => {
          if (!this.state.isHovered) {
            this.setState({ isPopupVisible: false });
          }
        }, 200); // Adjust the delay as needed
      };
    
      handleClickOutside = (event) => {
        if (this.popupRef.current && !this.popupRef.current.contains(event.target)) {
          this.setState({ isPopupVisible: false });
        }
      };
    
    //   componentWillUnmount() {
    //     document.removeEventListener('mousedown', this.handleClickOutside);
    //   }

    componentDidMount() {
        let data={
            "clientId": sessionStorage.getItem('CLIENT_ID')
        }
        axios.post(configuration.baseUrl+'/enrollment/getClient',data)
            .then(response=>{
                if(response.data.response){
                    let newPrgList = response.data.response.programList.split(',')
                    console.log('new----', newPrgList);
                    this.setState({
                        programsList: newPrgList,
                        association_logo : response.data.response.image,
                        loader : false
                    }, () => {
                        this.state.program = this.state.programsList[0]
                        this.getQuoteData(this.state.programsList[0])
                        // this.getQuoteData(this.state.programsList[0])
                    });
                }
            });
        window.scrollTo(0, 0);
        sessionStorage.setItem('current_screen', '2');
        sessionStorage.removeItem('Dependant');
        sessionStorage.removeItem('MemberDetails');
        sessionStorage.removeItem('coverage');
        sessionStorage.removeItem('postalCodeData');

        this.setState({
            loaderShow: true
          });
    
        let getDetails = JSON.parse(localStorage.getItem('CurrentLoginUser'));
        
        if(getDetails.email !== null || getDetails.email !== ''){
            this.setState({
                email:getDetails.email
            })
        }
      
        document.addEventListener('mousedown', this.handleClickOutside);

    }

    viewPlanHideModal = (event) => {
        this.setState({
            modalShow: false
        });
    }

    showPlansModal = (event) => {
        let obj = planConfig.find(obj => obj.CLIENT_ID.toString() === this.state.clientId.toString())
        window.open(obj.iframeURL);
        // this.setState({
        //     iframeURL : obj.iframeURL,
        //     modalShow: true,
        //      loaderShow : true
        // });
    };

    familyDetailsHideModal = (event) => {
        this.setState({
            familyDetails_ModalShow : false
        })
    }

    sentQuoteEmail = () => {
        this.setState({
            loaderShow : true,
            confirmationModal : false
        });
        //fetch(configuration.baseUrl + '/plan/sendQuoteMail/' + this.props.email + '/' + sessionStorage.getItem('isAgent'))
        fetch(configuration.baseUrl + '/plan/sendQuoteMail/' + this.state.email + '/' + sessionStorage.getItem('isAgent') + '/' + this.state.program)
            .then((response) => response.json())
            .then(response => {
                if(response.code === 200){
                    this.setState({
                        msgModal : true,
                        loaderShow : false,
                        errMsg : 'Quote email sent successfully!'
                    });
                } else {
                    this.setState({
                        msgModal : true,
                        loaderShow : false,
                        errMsg : 'Internal Server Error'
                    });
                }
            })
    }
    setUserValue = (value, isValid, parentDetails) => {
        if (parentDetails.name === 'email') {
            if (isValid) {
                this.state.email = value;
                this.setState({isvalidEmail:false})
            } else {
                this.state.email = null;
                this.setState({isvalidEmail:true})
            }
        }
       
    }
    saveEmail=()=>{

        let cookiesData = JSON.parse(sessionStorage.getItem('STATE_PARAM'))

        let dataObj = {
            "subId":cookiesData.subID,
            "email":this.state.email,
        }
        console.log("--dataObj --", dataObj);

        axios.post(configuration.baseUrl + '/setupfamily/saveEmail', dataObj)
        .then(response => {
            if (response.data.code === 200) {    

                let CurrentLoginUser = JSON.parse(localStorage.getItem('CurrentLoginUser'));
                let obj = {
                    userName: CurrentLoginUser.userName,
                    id: CurrentLoginUser.id,
                    email: this.state.email,
                    phone : CurrentLoginUser.phone
                };
                localStorage.setItem('CurrentLoginUser', JSON.stringify(obj));


                this.setState({
                    confirmationModal : true,
                    noEmail: false,
                    validationError: false
                })
            }else if (response.data.code === 202) { 
                this.setState({validationError: true});
            }
        });
           
    }
    clickConfirmationModal=()=>{
        
        if(this.state.email == '' || this.state.email == null ){
            this.setState({
                confirmationModal : true,
                noEmail: true
            })
        }else{
            this.setState({
                confirmationModal : true,
                noEmail: false
            })
        }
        
    }
    clickProceed = (e) => {
        if (e.detail === 1){
        let cookiesData = JSON.parse(sessionStorage.getItem('STATE_PARAM'))
        let subId = JSON.parse(sessionStorage.getItem('subId'))
        let dataObj = {
            "subId": cookiesData.subID || subId,
            "programCode": this.state.program,
        }

        axios.post(configuration.baseUrl + '/plan/saveMemberProgramType', dataObj)
            .then(response => {
                if (response.data.code === 200) {
                    this.props.onClick();
                }
            });
        }
    }
    render() {
        let classes = this.props;
        return (
            <div>
                {
                    this.state.loaderShow ? <Loader></Loader> : ''
                }
               
                {/* <div style={{ fontSize: '14px', lineHeight: '16px',textAlign:'justify' }}>{i18n.t('VIEW_QUOTE.QUOTE_TITLE')} <span style={{fontWeight:'bold'}}>{i18n.t('VIEW_QUOTE.QUOTE_TITLE2')}</span><span>{i18n.t('VIEW_QUOTE.QUOTE_TITLE3')}</span></div> */}

                                {/* <Grid xs={12} style={styles.QuickQt2TopWrp} item={true}>
                                    {
                                        ((this.state.clientId.toString() !== '6548' && this.state.clientId.toString() !== '4367') && (this.state.clientId.toString() !== '5540' && this.state.clientId.toString() !== '4376')) ?
                                        this.props.reEnroll? 
                                        <p style={{ fontSize: '14px', lineHeight: '16px',textAlign:'justify', fontFamily : 'Roboto, Arial, Helvetica, sans-serif',padding:'5px' }}>{i18n.t('VIEW_QUOTE.ReEnrollQUOTE_TITLE')}</p>
                                            :<p style={{ fontSize: '14px', lineHeight: '16px',textAlign:'justify', fontFamily : 'Roboto, Arial, Helvetica, sans-serif',padding:'5px' }}>
                                                {i18n.t('VIEW_QUOTE.QUOTE_TITLE')}
                                                <span style={{fontWeight:'bold'}}>{i18n.t('VIEW_QUOTE.QUOTE_TITLE2')}</span><span>{i18n.t('VIEW_QUOTE.QUOTE_TITLE3')}</span>
                                            </p> :
                                            <p style={{ fontSize: '14px', lineHeight: '16px',textAlign:'justify', fontFamily : 'Roboto, Arial, Helvetica, sans-serif',padding:'5px' }}>
                                                {i18n.t('VIEW_QUOTE.QUOTE_TITLE4')}
                                                <span style={{fontWeight:'bold'}}>{i18n.t('VIEW_QUOTE.QUOTE_TITLE5')}</span><span>{i18n.t('VIEW_QUOTE.QUOTE_TITLE6')}</span>
                                            </p>
                                    }
                                </Grid> */}

                                
                                {   (this.state.clientId.toString() == "4599" || this.state.clientId.toString() == "5742")  ?
                                <div style={{ fontSize: '14px', lineHeight: '16px', textAlign: 'justify', fontFamily: 'Roboto, Arial, Helvetica, sans-serif', padding: '5px' }} dangerouslySetInnerHTML={{__html:i18n.t('VIEW_QUOTE.QUOTE_TITLE65')}} />
                                :
                                    (this.state.clientId.toString() === '6548' 
                                    || this.state.clientId.toString() === '4367' 
                                    || this.state.clientId.toString() === '5540' 
                                    || this.state.clientId.toString() === '5647'
                                    || this.state.clientId.toString() === '4391' 
                                    || this.state.clientId.toString() === '5541' 
                                    || this.state.clientId.toString() === '4377'
                                    // || this.state.clientId.toString() === '5558'
                                    // || this.state.clientId.toString() === '4386'
                                    )  ?
                                    this.props.reEnroll?
                                    <p style={{ fontSize: '14px', lineHeight: '16px',textAlign:'justify', fontFamily : 'Roboto, Arial, Helvetica, sans-serif',padding:'5px' }}>{i18n.t('VIEW_QUOTE.ReEnrollQUOTE_TITLE')}</p>
                                    :
									 (this.state.clientId.toString() === '5541' || this.state.clientId.toString() === '4377') ?
                            
                            <p style={{ fontSize: '14px', lineHeight: '16px', textAlign: 'justify', fontFamily: 'Roboto, Arial, Helvetica, sans-serif', padding: '5px' }}>
                                {i18n.t('VIEW_QUOTE.QUOTE_CHSTITLE4')}
                                <span style={{ fontWeight: 'bold' }}>{i18n.t('VIEW_QUOTE.QUOTE_TITLE5')}</span><span>{i18n.t('VIEW_QUOTE.QUOTE_TITLE6')}</span>
                            </p>
							:
									
                                    <p style={{ fontSize: '14px', lineHeight: '16px',textAlign:'justify', fontFamily : 'Roboto, Arial, Helvetica, sans-serif',padding:'5px' }}>
                                        {this.state.clientId.toString() === '5647' || this.state.clientId.toString() === '4391' ?
                                                i18n.t('VIEW_QUOTE.QUOTE_TITLE')
                                        :
                                                i18n.t('VIEW_QUOTE.QUOTE_TITLE4')
                                }
                                                <span style={{fontWeight:'bold'}}>{i18n.t('VIEW_QUOTE.QUOTE_TITLE5')}</span><span>{i18n.t('VIEW_QUOTE.QUOTE_TITLE6')}</span>
                                            </p>
                                    :
                                    // (this.state.clientId.toString() !== '6548' && this.state.clientId.toString() !== '4367') && (this.state.clientId.toString() !== '5540' && this.state.clientId.toString() !== '4376') ?
                                    this.props.reEnroll?
                                    <p style={{ fontSize: '14px', lineHeight: '16px',textAlign:'justify', fontFamily : 'Roboto, Arial, Helvetica, sans-serif',padding:'5px' }}>{i18n.t('VIEW_QUOTE.ReEnrollQUOTE_TITLE')}</p>
                                    :
									
									(this.state.clientId.toString() === '5541' || this.state.clientId.toString() === '4377') ?
                            
                            <p style={{ fontSize: '14px', lineHeight: '16px', textAlign: 'justify', fontFamily: 'Roboto, Arial, Helvetica, sans-serif', padding: '5px' }}>
                                {i18n.t('VIEW_QUOTE.QUOTE_CHSTITLE4')}
                                <span style={{ fontWeight: 'bold' }}><b>{i18n.t('VIEW_QUOTE.QUOTE_TITLE5')}</b></span><span>{i18n.t('VIEW_QUOTE.QUOTE_TITLE6')}</span>
                            </p>
							:
                            (this.state.clientId.toString() === '5696' || this.state.clientId.toString() === '4397' || this.state.clientId.toString() === '5558' || this.state.clientId.toString() === '4386' || this.state.clientId.toString() === '5596' || this.state.clientId.toString() === '4390') ?
                            
                            <p style={{ fontSize: '14px', lineHeight: '16px', textAlign: 'justify', fontFamily: 'Roboto, Arial, Helvetica, sans-serif', padding: '5px' }}>
                                {i18n.t('VIEW_QUOTE.QUOTE_HLTITLE')}


                                <span style={{ fontWeight: 'bold' }}><b>{i18n.t('VIEW_QUOTE.QUOTE_TITLE5')}</b></span><span>{i18n.t('VIEW_QUOTE.QUOTE_TITLE6')}</span>
                            </p>
                            :
    
                                    <p style={{ fontSize: '14px', lineHeight: '16px',textAlign:'justify', fontFamily : 'Roboto, Arial, Helvetica, sans-serif',padding:'5px' }}>
                                    {i18n.t('VIEW_QUOTE.QUOTE_TITLE')}
                                    <span style={{fontWeight:'bold'}}>{i18n.t('VIEW_QUOTE.QUOTE_TITLE2')}</span><span>{i18n.t('VIEW_QUOTE.QUOTE_TITLE3')}</span>
                                </p>

                                }







                                    <Grid container >
                    <Grid xs={12} style={{tableLayout:'fixed',marginTop:'20px',marginBottom:'20px'}} item={true}>
                    <div style={{ overflowX: "auto" }} >
                    <CommonTable getQuote={(val) => this.getQuoteData(val)} program={this.state.program} programsList={this.state.programsList} quoteData={this.state.generalQuote} check={true} headerData={this.state.generalQuoteHeader} tooltip={this.state.tooltip} quickQuote={true} totalReq={true} ACSM={this.state.acsmFlag} clientId = {this.state.clientId} />
                    </div>
                    </Grid>
                    </Grid>

                <Grid container justify='space-between'>
                    <Grid item xs={12} sm={7}>
                    <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                        {(this.state.clientId.toString() == "4599" || this.state.clientId.toString() == "5742") ?
                            <Grid item xs={12} sm={5}>
                                <ViewButton2
                                    variant="contained"
                                    color="primary"
                                    onClick={this.showPlansModal}
                                    style={{ width: '100%' }}
                                >Senior 65 Program Brochure</ViewButton2>
                            </Grid>
                            :
                            ((this.state.clientId !== '6548' && this.state.clientId !== '4367')
                                // && (this.state.clientId !== '5540' && this.state.clientId !== '4376'  
                                && this.state.clientId !== '5541' && this.state.clientId !== '4377'
                                && this.state.clientId !== '5558' && this.state.clientId !== '4386'
                                && this.state.clientId !== '5696' && this.state.clientId !== '4397'
                                && this.state.clientId !== '4390' && this.state.clientId !== '5596'
                            ) ? <>
                                    <Grid item xs={12} sm={5}>
                                        <ViewButton2
                                            variant="contained"
                                            color="primary"
                                            onClick={this.showPlansModal}
                                            hidden={this.props.reEnroll || this.state.program != "UHF"}
                                            style={{ width: '100%' }}
                                        >View All UHS Standard Programs</ViewButton2>
                                    </Grid>
                                    {(!isnoSmartShare(this.state.clientId) || this.state.program != "UHF") &&
                                        <Grid item xs={12} sm={5}>
                                            <ViewButton2
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    this.setState({ smartShareModel: true, loaderShow: true })
                                                }}
                                                style={{ width: '100%' }}
                                                hidden={this.state.clientId === "4350" || this.props.reEnroll || this.state.program != "UHF" || this.state.clientId === "5647" || this.state.clientId === "4391"}
                                            >View All UHS SmartShare Programs</ViewButton2>
                                        </Grid>
    }
                                        <Grid item xs={12} sm={5} hidden={this.state.clientId === "4350" || this.state.program != "UHF" || this.state.clientId === "5647" || this.state.clientId === "4391"}>
                                            <ViewButton2
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    this.setState({ACSMModal: true})
                                                }}
                                                style={{width: '100%'}}
                                            >{this.props.reEnroll?"Learn about the ACSM Add-On Option":"ACSM value add-on for UHS Standard programs"}</ViewButton2>
                                        </Grid>
                                    {(this.state.clientId === "5503" || this.state.clientId === "4364") 
                                    && (this.state.clientId != "4350" && this.state.program != "UHF") 
                                    && <Grid item xs={12} sm={5}>
                                        <ViewButton2
                                            variant="contained"
                                            color="primary"
                                            onClick={() => window.open('https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Thrive-Program-Grid.pdf')}
                                            style={{ width: '100%' }}
                                        >View All Thrive Programs</ViewButton2>
                                    </Grid>}
                                    {this.state.program == "Prosper"
                                           && <Grid item xs={12} sm={5}>
                                        <ViewButton2
                                            variant="contained"
                                            color="primary"
                                            onClick={() => window.open('https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Prosper-Program-Brochure-Grid.pdf')}
                                            style={{ width: '100%' }}
                                        >View all Prosper Programs</ViewButton2>
                                    </Grid>}
                                    {this.state.program == "STX"
                                           && <Grid item xs={12} sm={5}>
                                        <ViewButton2
                                            variant="contained"
                                            color="primary"
                                            onClick={() => window.open('https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-STx-Program-Grid.pdf')}
                                            style={{ width: '100%' }}
                                        >View all STx Programs</ViewButton2>
                                    </Grid>}
                                </>
                                :
                                (this.state.clientId !== '5541' && this.state.clientId !== '4377' && (this.state.clientId === '5558' || this.state.clientId === '5596' || this.state.clientId === '4386' || this.state.clientId === '4390' || this.state.clientId === '5696' || this.state.clientId === '4397')) ?
                                    <>
                                        <Grid item xs={12} sm={5}>
                                            <ViewButton2
                                                variant="contained"
                                                color="primary"
                                                onClick={this.showPlansModal}
                                                style={{ width: '100%' }}
                                            >View All Healthy Life (HL) Programs</ViewButton2>
                                        </Grid>

                                        {
                                            this.state.clientId !== '5558' && this.state.clientId !== '4386' && this.state.clientId !== '5696' && this.state.clientId !== '4397' &&
                                            <Grid item xs={12} sm={5}>
                                                <ViewButton2
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        this.setState({ smartShareModel: true, loaderShow: true })
                                                    }}
                                                    style={{ width: '100%' }}
                                                >View All UHS SmartShare Programs</ViewButton2>
                                            </Grid>
                                        }

                                        {this.state.clientId === '4390' || this.state.clientId.toString() === '5696' || this.state.clientId.toString() === '4397' || this.state.clientId.toString() === '5558' || this.state.clientId.toString() === '4386' || this.state.clientId.toString() === '4390' || this.state.clientId.toString() === '5596' ?
                                            null
                                            :
                                            <Grid item xs={12} sm={5}>
                                                <ViewButton
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => { this.setState({ easyShareModal: true, loaderShow: true }) }}
                                                    style={{ width: '100%' }}
                                                >View EasyShare (ES) Programs</ViewButton>
                                            </Grid>
                                        }
                                    </>
                                    :
                                    (this.state.clientId === '4386' || this.state.clientId === '5558' || this.state.clientId === '5696' || this.state.clientId === '4397') ?
                                        <>
                                            
                                                <Grid item xs={12} sm={5}>
                                                    <ViewButton2
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.showPlansModal}
                                                        style={{ width: '100%' }}
                                                    >View All Healthy Life (HL) Programs</ViewButton2>
                                                </Grid>
                                            
                                        </>
                                        :
                                        
                                            <Grid item xs={12} sm={5}>
                                                <ViewButton2
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.showPlansModal}
                                                    style={{ width: '100%' }}
                                                >View all Community HealthShare Programs</ViewButton2>
                                            </Grid>

                                       
                        }
                     {(this.state.clientId.toString() != "4599" &&
                     this.state.clientId.toString() != "5742" && 
                      this.state.clientId.toString() != "4386" && 
                      this.state.clientId.toString() != "5558" && 
                      this.state.clientId.toString() != "5696" && 
                      this.state.clientId.toString() !== "5541" && 
                      this.state.clientId.toString() !== "4377" &&
                      this.state.clientId.toString() != "4397") &&
                      <Grid item xs={12} sm={5} 
                       style={{ position: 'relative', display: 'inline-block' }}
                        onMouseEnter={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave}>
                                <ViewButton2
                                    variant="contained"
                                    color="primary"
                                    onClick={()=>{window.open('https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHSRx-Brochure.pdf')}}
                                    style={{ width: '100%' }}
                                >View UHSRx Program</ViewButton2>
                                {this.state.isPopupVisible && (
                                    <div
                                        ref={this.popupRef}
                                        style={{
                                            position: 'absolute',
                                            top: '-40%',
                                            marginLeft: '297px',
                                            backgroundColor:'rgb(234, 232, 219)',
                                            border: '1px solid black',
                                            padding: '10px',
                                            zIndex: 1,
                                            fontSize: '11px',
                                            color: 'black',
                                            width: '350px',
                                            textAlign: 'justify',
                                            borderRadius: '6px',
                                        }}
                                    >
                                    Administered by Manifest Pharmacy (a ReviveHealth company), UHSRx provides access 
                                    to over 1,000 no-cost medications available through mail order (chronic needs) and 
                                    in-store pharmacy pickup (urgent needs) at over 70,000 retail pharmacies. Most 
                                    prescriptions are filled with a 90-day supply. The UHSRx program is FREE to all 
                                    UHF members. Drugs that are eligible for sharing through UHSRx are listed on the 
                                    respective formularies. To view the Home Delivery formulary for chronic needs, 
                                    <a target="_blank" href='https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHSRx-Home-Delivery-Formulary.pdf'> click here</a>. 
                                    To view the Urgent formulary for urgent needs,
                                    <a target="_blank" href='https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHSRx-Urgent-Formulary.pdf'> click here</a>.
                                    </div>
                                    )}
                            </Grid>}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4} style={{ paddingRight: '26px' }}>
                        <span style={customStyle.headingTxt}>
                            Other applicable fees across all programs
                        </span>
                        {
                            Object.keys(this.state.otherQuoteData).map((keyName, i) => (
                                <div style={customStyle.planBoxViewQuote} key={i}>
                                    <span style={customStyle.planText1}>{keyName}</span>
                                    <span style={customStyle.planPrice}>{this.state.otherQuoteData[keyName]}</span>
                                </div>

                            ))
                        }
                    </Grid>

                </Grid>

                <div style={customStyle.bottomMainConatiner}>
                        <div style={customStyle.newBottomContainer}>
                            <div style={customStyle.bottomChildContainer1}>

                                <ProceedButton
                                    variant="contained"
                                    color="primary"
                                    onClick={this.clickConfirmationModal}
                                    style={{width: '150px', marginRight : '10px'}}
                                >SEND QUOTE</ProceedButton>
                                <ProceedButton
                                    variant="contained"
                                    color="primary"
                                    onClick={this.clickProceed}
                                    style={{width: '120px', height: '40px'}}
                                >{i18n.t('BUTTON.PROCEED')}</ProceedButton>
                            </div>
                            <div style={customStyle.bottomChildContainer2}>
                                
                            </div>           
                        </div>
                        <div style={customStyle.newBottomContainer}>
                           
                        </div>
                </div> 
                <Modal size="xl" show={this.state.modalShow} onHide={(event) => this.viewPlanHideModal(event)}>
                    <Modal.Header style={customStyle.modal_header} closeButton>
                        <Modal.Title>{i18n.t('VIEW_QUOTE.MODEL_TITLE')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: '0' }}>
                        {
                            this.state.loaderShow ? <Loader></Loader> : ''
                        }
                        {
                            <iframe 
                            style={IFrameStyle}
                            
                            onLoad={()=>this.setState({loaderShow:false})}  src={this.state.iframeURL}></iframe>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton onClick={() => this.viewPlanHideModal()}>{i18n.t('BUTTON.DONE')}</CustomButton>
                    </Modal.Footer>
                </Modal>

                {/* --------------------------------ACSM Modal ------------------------------------------------- */}

                <Modal size="lg" show={this.state.ACSMModal}  onHide={() => {this.setState({ ACSMModal : false})}} backdrop="static">
                    <Modal.Header style={customStyle.modal_header} closeButton>
                        <Modal.Title>Annual Co-Share Maximum for UHS Standard Programs Only</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: '15px' ,textAlign : 'justify', overflowX : 'hidden', fontfamily: 'Roboto, Arial, Helvetica, sans-serif'}}>
                        {
                            this.state.loaderShow ? <Loader></Loader> : ''
                        }
                        <span style={{fontSize:'13px', fontFamily : 'Roboto, Arial, Helvetica, sans-serif'}}>If you choose this option, 100% of your Eligible Medical Expenses become sharable when your Annual Co-Share Maximum (ACSM)
               has been met. The ACSM differs for each program. Also, the Preventive Care is increased to $1,000 per member per year
               (subject to a 90-day waiting period). Check the box for the ACSM option and you can view the difference in monthly contribution
               amounts for each program.
               </span>

                        <div style={{ overflowX: "auto" }} >
                            <Table aria-label="customized table1"  style={customStyle.tableACSM}>
                            <TableHead style={{backgroundColor:'#420045',position: 'sticky'}}>
                                <TableRow style={{position: 'sticky'}}>
                                    <StyledTableCell1 padding='checkbox' align='center' style={customStyle.tableHead1ACSM} >
                                        Program Name
                                    </StyledTableCell1>
                                    {
                                        this.state.generalQuoteHeader.length > 0 && (this.state.generalQuoteHeader.length - 1 === 7 || isnoSmartShare(this.state.clientId)) ?
                                            this.state.ACSMPlanAll.map((option, index) => (
                                                <StyledTableCell1 style={customStyle.tableHead2ACSM}  align='center' key={index} value={option.id}>
                                                    {option}
                                                </StyledTableCell1>

                                            )) :
                                            this.state.ACSMPlan.map((option, index) => (
                                                <StyledTableCell1 style={customStyle.tableHead2ACSM}  align='center' key={index} value={option.id}>
                                                    {option}
                                                </StyledTableCell1>

                                            ))

                                    }
                                </TableRow>
                            </TableHead>

                            <TableBody >
                                <StyledTableRow align='center' style={customStyle.rowHead} >
                                    <StyledTableCell1  align="center" style={customStyle.tableRowHeadACSM} >
                                        <text style={{color: '#fff'}}>Annual Out-of-Pocket</text>
                                    </StyledTableCell1>
                                    <StyledTableCell1  align="center" colSpan={6} style={customStyle.tableRowHead2ACSM} >
                                    <text style={{color: '#fff'}}>Annual Co-Share Maximum Amounts</text>
                                    </StyledTableCell1>
                                </StyledTableRow>
                                {
                                    (this.state.generalQuoteHeader.length - 1 === 7 || isnoSmartShare(this.state.clientId)) ?
                                        ACSMrowUhsAll.map((row,index) => (

                                            <StyledTableRow align="left" style={{backgroundColor:'rgb(234, 232, 219)',border : '2px solid #ffffff'}} key={index}>
                                                <StyledTableCell component="th" scope="row" style={customStyle.cellTitle}>
                                                    {row.NAME}
                                                </StyledTableCell>
                                                <StyledTableCell align="center" style={customStyle.cellChild}  >{row.UHS1}</StyledTableCell>
                                                <StyledTableCell align="center" style={customStyle.cellChild} >{row.UHS2}</StyledTableCell>
                                                <StyledTableCell align="center" style={customStyle.cellChild}>{row.UHS3}</StyledTableCell>
                                                <StyledTableCell align="center" style={customStyle.cellChild} >{row.UHS4}</StyledTableCell>
                                                <StyledTableCell align="center" style={customStyle.cellChild} >{row.UHS5}</StyledTableCell>
                                                <StyledTableCell align="center" style={customStyle.cellChild} >{row.UHS6}</StyledTableCell>
                                            </StyledTableRow>
                                        )) :
                                        ACSMrowUhs.map((row,index) => (

                                            <StyledTableRow align="center" style={{backgroundColor:'rgb(234, 232, 219)',border : '2px solid #ffffff'}} key={index}>
                                                <StyledTableCell component="th" scope="row" style={customStyle.cellTitle}>{row.NAME}</StyledTableCell>
                                                <StyledTableCell align="center" style={customStyle.cellChild} >{row.UHS1}</StyledTableCell>
                                                <StyledTableCell align="center" style={customStyle.cellChild} >{row.UHS2}</StyledTableCell>
                                                <StyledTableCell align="center" style={customStyle.cellChild} >{row.UHS3}</StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                }


                            </TableBody>

                        </Table>
                        </div>
                        <span style={{fontSize:'13px', fontFamily : 'Roboto, Arial, Helvetica, sans-serif'}}>
                        The amounts shown above are Co-Share Maximum amounts in effect as of 10/01/2020. Annual Co-Share Maximum amounts are subject
                        to adjustment from time to time. Always remember to check the Universal HealthShare Member Portal for the most current version
                        of the Sharing Guidelines, which may reflect changes that have been made since the date of the last copy you reviewed.
                        This option is only available at the time of enrollment, or at the time of annual program renewal.
                </span>
                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton  onClick={() => {this.setState({ ACSMModal : false})}}>{i18n.t('BUTTON.DONE')}</CustomButton>
                    </Modal.Footer>
                </Modal>

                {/*================================ smart share modal ==========================*/}
                <Modal size="lg" show={this.state.smartShareModel}  onHide={() => {this.setState({ smartShareModel : false})}} backdrop="static">
                    <Modal.Header style={customStyle.modal_header} closeButton>
                        <Modal.Title>UHS SmartShare</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: '0px'}}>
                        {
                            this.state.loaderShow && <Loader></Loader>
                        }
                        <iframe style={{ height: '430px', width: '100%' }}  onLoad={()=>this.setState({loaderShow:false})}  src={'https://www.universalhealthfellowship.org/uhs-smartshare-program-grid/'}></iframe>

                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton  onClick={(event) => {this.setState({smartShareModel : false})}}>{i18n.t('BUTTON.DONE')}</CustomButton>
                    </Modal.Footer>
                </Modal>

                <Modal size="xs" show={this.state.msgModal} onHide={(event) => this.setState({msgModal:false,loaderShow : false, errMsg : ''})} backdrop="static" centered>
                    <Modal.Header style={customStyle.modal_header} closeButton>
                        <Modal.Title>Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ margin: '10px',textAlign:'center',fontFamily : 'Roboto, Arial, Helvetica, sans-serif' }}>
                        {this.state.errMsg}
                    </Modal.Body>
                    <Modal.Footer style={{alignItems:'right'}}>
                        <CustomButton style={{marginTop: '10px', width: '50px', height: '40px'}} onClick={()=>{this.setState({ msgModal : false,loaderShow : false, errMsg : ''})}}>Ok</CustomButton>
                    </Modal.Footer>
                </Modal>

                <Modal size="xs" show={this.state.confirmationModal} onHide={(event) => this.setState({confirmationModal:false,loaderShow : false})} backdrop="static" centered>
                    <Modal.Header style={customStyle.modal_header} closeButton>
                        <Modal.Title>Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ margin: '10px',textAlign:'center',fontFamily : 'Roboto, Arial, Helvetica, sans-serif' }}>
                        {/* The quote will be emailed to <b>{this.props.email}</b>. Confirm to proceed. */}
                       {this.state.noEmail ? 
                        <div style={{flexGrow:1, textAlign:'left'}}>
                            <Grid xs={12} sm={12} md={6} lg={12} style={{marginBottom : '5px', fontFamily : 'Roboto, Arial, Helvetica, sans-serif', fontSize : '14px'}}>
                                <span>Please confirm email to send quote</span>
                            </Grid>
                            <Grid xs={12} sm={12} md={6} lg={12}>
                                <Sample setChild={this.setUserValue.bind(this)} reqFlag={true} name={'email'} label={'Email ID'} value={this.state.email} disable={false} style={customStyle.textFieldWrp} length={50} fieldType={'email'} errMsg={'Enter valid email Id'} helperMsg={"Email is required"} parentDetails={{ name: 'email' }}></Sample>
                            </Grid>
                   
                        </div>
                        :
                        <>
                        The quote will be emailed to <b>{this.state.email}</b>. Confirm to proceed. 
                        </>
                        }

                        {this.state.validationError ?
                            <div style={{color:'#f30', fontSize:'12px', textAlign:'left', padding:'10px 0'}}> Email already exist in system </div>
                            :
                            null
                        }
                       

                    </Modal.Body>
                    <Modal.Footer style={{alignItems:'right'}}>
                    {this.state.noEmail ? 
                        <CustomButton style={{width: '90px', height: '40px'}} disabled={this.state.isvalidEmail ? true : false} onClick={()=>this.saveEmail()}>Save</CustomButton>
                        :
                        <CustomButton style={{width: '90px', height: '40px'}} onClick={()=>this.sentQuoteEmail()}>Confirm</CustomButton>
                        }
                        {/* <CustomButton style={{width: '90px', height: '40px'}} onClick={()=>this.sentQuoteEmail()}>Confirm</CustomButton> */}
                        <CustomButton style={{marginLeft: '10px', width: '70px', height: '40px'}} onClick={()=>{this.setState({ confirmationModal : false,loaderShow : false})}}>Cancel</CustomButton>
                    </Modal.Footer>
                </Modal>


                <Modal size="xl" show={this.state.easyShareModal}  onHide={(event) => { this.setState({easyShareModal : false}) }} backdrop="static">
                    <Modal.Header style={customStyle.modal_header} closeButton>
                        <Modal.Title>UHS EasyShare</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: '0px'}}>
                        {
                            this.state.loaderShow && <Loader></Loader>
                        }
                        <iframe style={{ height: '430px', width: '100%' }}  onLoad={()=>this.setState({loaderShow:false})}  src={'https://www.universalhealthfellowship.org/uhs-easyshare-program-grid/'}></iframe>

                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton  onClick={(event) => {this.setState({easyShareModal : false})}}>{i18n.t('BUTTON.DONE')}</CustomButton>
                    </Modal.Footer>
                </Modal>

              
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        subId: state.subId,
        email : state.email
    };
  }

export default withStyles(styles)( connect(mapStateToProps)(ViewQuote));